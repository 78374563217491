import React from 'react'
import { Form, Formik } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { SIGN_IN } from '../urls'
import { email, required, validator } from '../utils/validators'
import Password from '../components/common/Password'
import { isAuthenticated, signin } from '../utils/auth'
import LayoutAuth from '../components/LayoutAuth'

export default function Login() {
    const history = useHistory()
    const signIn = usePostRequest({ url: SIGN_IN })
    const user = localStorage.getItem('user')
    if (isAuthenticated()) {
        return <Redirect to={user.admin ? '/borrow' : '/borrow'} />
    }

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })
        if (success) {
            signin(response, history)
        }
    }

    return (
        <LayoutAuth sidebar={(
            <div>
                <div className="mb-3">
                    <h2 className="is-size-3 has-text-weight-bold">Добро пожаловать!</h2>
                </div>
            </div>
        )}>

            <div id="login-title-panel" className="has-text-centered mb-4">
                <h2 className="is-size-4 has-text-weight-bold mb-4">Войдите в систему</h2>
            </div>

            <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input name="email" validate={validator(required, email)} placeholder="Электронная почта" />
                    <Password name="password" validate={required} placeholder="Пароль" />

                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.loading}
                                className="is-primary is-blue is-medium is-fullwidth"
                                text="Войти"
                                type="submit" />
                        </div>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}
