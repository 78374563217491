import { getEnv } from './utils/environment'

export const MARKET_LIST = '/borrow/market'
export const MARKET_DETAIL = '/borrow/market/{id}'
export const ORDER_LIST = '/borrow/order'
export const ORDER_PRODUCT_LIST = '/borrow/order_product'
export const ORDER_DETAIL = '/borrow/order/{id}'
export const SEND_TO_TELEGRAM = '/borrow/send_to_telegram/{orderId}'
export const ORDER_PRODUCT_DETAIL = '/borrow/order_product/{id}'
export const TERRITORY_LIST = '/borrow/territory'
export const TERRITORY_DETAIL = '/borrow/territory/{id}'
export const STATUS_UPDATE = '/borrow/change_status/{orderId}'
export const CHECK_PASSWORD = '/borrow/validate_password'
export const SIGN_IN = '/users/sign-in'
export const TOTAL_BORROW_LIST = '/borrow/total_borrow'

export const SIGN_UP = '/users/sign-up'
export const SIGN_OUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const CHANGE_PASSWORD = '/users/change_password'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'
export const USER_LIST = '/users/user'

export const PRODUCT_LIST = '/product/products'
export const BORROW_LIST = '/borrow/borrow'
export const BORROW_DETAIL = '/borrow/borrow/{id}'
export const PRODUCT_DETAIL = '/product/product/{id}'
export const BRAND_LIST = 'product/brand'
export const BRAND_DETAIL = 'product/brand/{id}'
export const CATEGORY_LIST = '/product/categories'
export const CATEGORY_PARENT_LIST = '/product/parent-categories'
export const CATEGORY_DETAIL = '/product/category/{id}'
export const CLIENT_LIST = 'borrow/client'
export const CLIENT_DETAIL = 'borrow/client/{id}'
export const SEND_MESSAGE = 'borrow/send_sms_getaway/{clientId}'
export const SEND_MESSAGE_SELECT = 'borrow/send_sms_select'
export const ALL_SEND_MESSAGE = 'borrow/send_sms_getaway'
export const ORDER_ARCHIVE_LIST = 'borrow/order-archive'
export const CLIENT_EXPORT = 'borrow/client/export'

const backendUrl = process.env.REACT_APP_BASE_URL || `${window.location.protocol}//${window.location.hostname}`
export const domain = backendUrl.endsWith('/') ? backendUrl.substr(0, backendUrl.length - 1) : backendUrl

// eslint-disable-next-line no-nested-ternary
const frontUrl = getEnv() === 'production'
    ? (
        ''
    ) : getEnv() === 'staging' ? (
        ''
    ) : 'http://localhost:3000'

export const frontDomain = frontUrl.endsWith('/') ? frontUrl.substr(0, frontUrl.length - 1) : frontUrl
