import React, { useState } from 'react'
import cn from 'classnames'
import { StyleSheet } from 'aphrodite'
import Button from './common/Button'
import { SEND_MESSAGE } from '../urls'
import { useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat, timeAgo } from '../utils/date'
import { format } from '../utils/number'
import ClientBorrowList from './ClientBorrowList'
import { useMessage } from '../hooks/message'

export default function BorrowItem({
    item,
    clients,
    onCheckboxChange,
}) {
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    const [showClientBorrowList, hideClientBorrowList] = useModal(
        <ClientBorrowList
            clients={clients}
            client={item}
            clientId={item.id}
            hideModal={() => hideClientBorrowList()}
        />,
        styles.modal,
    )
    const currentDate = new Date()

    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked
        setIsChecked(newCheckedState)
        onCheckboxChange(item.id, newCheckedState)
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td onClick={showClientBorrowList} className="">
                <span className={cn('tag is-medium is-light', `${item.sumAmount > 0 ? 'is-danger' : 'is-success'}`)}>
                    {item.name}
                </span>
            </td>
            <td className="">{item.phoneNumber}</td>
            <td className="">{format(item.sumAmount)}</td>
            <td>
                <input
                    className="checkbox"
                    style={{
                        width: 25,
                        height: 25,
                    }}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td className="">{item.lastSendDate ? timeAgo(item.lastSendDate) : ''}</td>
            <td className="has-text-right">
                <Button
                    loading={sendMessage.loading}
                    onClick={onSend}
                    className="is-link"
                    icon="paper-plane-outline" />
            </td>
        </tr>

    )
}

const styles = StyleSheet.create({
    modal: {
        width: 1000,
    },
})
