import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { LanguageProvider } from './contexts/LanguageContext'
import Login from './pages/Login'
import ProtectedRoute from './components/common/ProtectedRoute'
import Borrow from './pages/Borrow'
import Client from './pages/Client'
import Users from './pages/Users'
import SettingsUser from './pages/SettingsUser'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <LanguageProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <ProtectedRoute path="/client" component={Client} exact />
                        <ProtectedRoute path="/users" component={Users} exact />
                        <ProtectedRoute path="/borrow" component={Borrow} exact />
                        <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </LanguageProvider>
    )
}
